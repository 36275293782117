
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { rectanglesPositions } from './components/RectanglesPositions';
import Navigation from './components/Navigation';
import PanelTab from './components/Tabs/components/PanelTab';
import IntegrationsTab from './components/Tabs/components/IntegrationsTab';
import ApiTab from './components/Tabs/components/ApiTab';
import classes from './index.module.scss';
import LayoutWithLabel from '@/components/common/LayoutWithLabel';
import { Section } from '@/components/common/Section';
import SectionBody from '@/components/common/SectionBody';
import Carousel from '@/components/Carousel';
const HowDoesSmsapiWork: React.FunctionComponent = () => {
    const { t } = useTranslation('home');
    const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
    const [hasBeenCodeExamplesLoaded, setHasBeenCodeExamplesLoaded] = useState(false);
    if (currentSlideIndex === 1 && !hasBeenCodeExamplesLoaded) {
        setHasBeenCodeExamplesLoaded(true);
    }
    return (<Section backgroundColor={'white'} disableContentPaddingHorizontal disableBorderBottom>
      <SectionBody main={<LayoutWithLabel layoutSettings={{
                twoColoredBackground: true,
                withBackgroundRectangles: true,
            }} rectangles={rectanglesPositions} label={t('SMSAPI in practice')} heading={t('How does SMSAPI work?')} header={<Navigation items={['Customer Portal', 'API', 'Integrations']} currentSlideIndex={currentSlideIndex} handleClick={setCurrentSlideIndex}/>} main={<div className={classes.wrapper}>
                <Carousel currentSlideIndex={currentSlideIndex} hideSlideIndicators buttonsSettings={{
                    md: 'none',
                }} pages={[
                    <PanelTab key={1}/>,
                    <ApiTab key={2} shouldLoadCodeExamples={currentSlideIndex === 1 || hasBeenCodeExamplesLoaded}/>,
                    <IntegrationsTab key={3}/>,
                ]} onSlideChange={(index) => {
                    setCurrentSlideIndex(index);
                }} gtm="how-smsapi-works"/>
              </div>}/>}/>
    </Section>);
};
export default HowDoesSmsapiWork;

    async function __Next_Translate__getStaticProps__19174cc4605__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/HowDoesSmsapiWork/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19174cc4605__ as getStaticProps }
  