
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from 'next-translate/useTranslation';
import clsx from 'clsx';
import classes from './index.module.scss';
interface Props {
    items: string[];
    currentSlideIndex: number;
    handleClick: React.Dispatch<React.SetStateAction<number>>;
}
const Navigation: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ items, currentSlideIndex, handleClick, }) => {
    const { t } = useTranslation('home');
    return (<div className={classes.buttonList}>
      {items.map((item, index) => (<button className={clsx(classes.tabButton, index === currentSlideIndex && classes.tabButton_active)} key={t(item)} onClick={() => handleClick(index)}>
          {t(item)}
        </button>))}
    </div>);
};
export default Navigation;

    async function __Next_Translate__getStaticProps__19174cc4d00__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/HowDoesSmsapiWork/components/Navigation/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19174cc4d00__ as getStaticProps }
  